import type { IMessages } from '~/server/api/translations'

export const getMessages = async (): Promise<IMessages> => {
  try {
    const response = await useFetch('/api/translations')
    if (!response?.data?.value) {
      console.warn('Unexpected response structure:', response)
      return {}
    }
    return response.data.value
  } catch (error) {
    console.error('Error fetching translations:', error)
    return {}
  }
}
